<template>
  <div>
    <app-card>
      <template #body>
        <role-form :role="role" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'rolesList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-role-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

    <app-modal
        id="delete-role-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_role') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import RoleForm from '@/views/roles/components/RoleForm';

export default {
  components: {
    AppCard,
    AppModal,
    RoleForm
  },
  data: () => ({
    form: {}
  }),
  beforeMount() {
    this.$store.dispatch('rolesStore/GET_ROLE', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      role: 'rolesStore/ROLE'
    })
  },
  methods: {
    updateItem() {
      this.$store.dispatch('rolesStore/UPDATE', {id: this.role.id, data: this.form}).then(response => {
        this.$router.push({name: 'rolesList'});
      });
    },
    deleteItem() {
      this.$store.dispatch('rolesStore/DELETE', this.role.id).then(() => {
        this.$router.push({name: 'rolesList'});
      });
    }
  }
}
</script>